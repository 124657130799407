.tags-container {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 15px;
  margin-left: -1px;
  color: white;
  .tag {
    border-radius: 5px;
    padding: 4px 8px;
    margin-right: 5px;
  }
}
